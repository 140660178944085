/**
  Providing simple sane defaults
  for some HTML elements.

  Most styling should be done on a per-component basis,
  and this isn't meant to be a usable CSS library.
*/

* {
   -webkit-font-smoothing: antialiased;
   box-sizing: border-box;
}

html {
   height: 100%;
   font-family: CiscoSans, Helvetica, Arial, "Lucida Grande", sans-serif;
   /** 10px font size is for backwards compatibility for app teams using "REM" for sizing */
   font-size: 10px !important;
}

body {
   height: 100%;
   overflow: hidden;
   background-color: var(--body-background, #ffffff);
   font-size: 14px;
   line-height: 1.42857143;
   color: var(--secondary-body-text, #535b62);

   --platform-banner-height: 0px;
   --platform-header-height: 56px;
   --platform-top-shift: calc(
      var(--platform-banner-height) + var(--platform-header-height)
   );
   --hbr-app-shift-top: calc(
      var(--platform-banner-height) + var(--platform-header-height)
   );
}


h1 {
   color: var(--header-2-color-primary, #535b62);
   margin: 12px 0;
   font-size: 24px;
   font-weight: 300;
   line-height: 30px;
   letter-spacing: 0.5px;
}

h2 {
   color: var(--header-2-color-primary, #535b62);
   margin: 12px 0;
   font-weight: 300;
   font-size: 18px;
   letter-spacing: 0.75px;
   line-height: 26px;
}

h3 {
   color: var(--header-2-color-primary, #535b62);
   font-weight: 300;
   font-size: 18px;
   line-height: 24px;
   margin-top: 20px;
   margin-bottom: 10px;
}

h4 {
   color: var(--header-2-color-primary, #535b62);
   font-weight: 400;
   font-size: 14px;
   line-height: 20px;
   margin-top: 36px;
   text-transform: uppercase;
   letter-spacing: 1px;
} 

h5,
h6 {
   color: var(--header-2-color-primary, #535b62);
   font-size: 12px;
   font-weight: 100;
   line-height: 1.1;
}

a {
   cursor: pointer;
   text-transform: none;
   font-weight: 400;
   letter-spacing: 0;
   color: var(--link-normal, #1170cf);
   text-decoration: none;
}

a:hover {
   color: var(--link-hover, #0353a8);
}

b {
   font-weight: 700;
}

strong {
   font-weight: 700;
}

/* Magnetic-iteration config*/
body.hbr-theme-magnetic.hbr-css__text-secondary-sm {
   font-family: var(--hbr-font-body, Inter);
   --hbr-color-brand-95: var(--hbr-color-blue-95);
  --hbr-color-brand-90: var(--hbr-color-blue-90);
  --hbr-color-brand-85: var(--hbr-color-blue-85);
  --hbr-color-brand-80: var(--hbr-color-blue-80);
  --hbr-color-brand-70: var(--hbr-color-blue-70);
  --hbr-color-brand-65: var(--hbr-color-blue-65);
  --hbr-color-brand-60: var(--hbr-color-blue-60);
  --hbr-color-brand-55: var(--hbr-color-blue-55);
  --hbr-color-brand-50: var(--hbr-color-blue-50);
  --hbr-color-brand-45: var(--hbr-color-blue-45);
  --hbr-color-brand-40: var(--hbr-color-blue-40);
  --hbr-color-brand-35: var(--hbr-color-blue-35);
  --hbr-color-brand: var(--hbr-color-brand-45);
  --hbr-color-brand-hover: var(--hbr-color-brand-40);
  --hbr-color-brand-active: var(--hbr-color-brand-40);
  --hbr-color-brand-selected: var(--hbr-color-brand-50);
  --hbr-color-brand-focus: var(--hbr-color-brand-85);
  --hbr-color-brand-disabled: var(--hbr-color-brand-80);
  --hbr-color-brand-highlight: #45991F4D;
  --hbr-color-brand-medium: var(--hbr-color-brand-50);
  --hbr-color-brand-medium-disabled: var(--hbr-color-brand-85);
  --hbr-color-brand-weak: var(--hbr-color-brand-65);
  --hbr-color-brand-weak-highlight: var(--hbr-color-brand-95);
  --hbr-color-brand-bg: var(--hbr-color-brand-95);
  --hbr-color-brand-bg-medium: var(--hbr-color-brand-90);
  --hbr-color-brand-bg-weak: var(--hbr-color-brand-85);
  --hbr-color-brand-bg-hover: var(--hbr-color-brand-90);
  --hbr-color-brand-bg-medium-hover: var(--hbr-color-brand-85);
  --hbr-color-brand-bg-weak-hover: var(--hbr-color-brand-80);
  --hbr-color-brand-bg-selected: var(--hbr-color-brand-95);
  --hbr-color-brand-bg-weak-selected: var(--hbr-color-brand-40);
  --hbr-color-brand-bg-weak-selected-hover: var(--hbr-color-brand-35);
  --hbr-color-brand-bg-disabled: var(--hbr-color-brand-95);
  --hbr-color-brand-bg-medium-disabled: var(--hbr-color-brand-95);
  --hbr-color-brand-vivid-bg: var(--hbr-color-brand-35);
  --hbr-color-brand-text: var(--hbr-color-brand-45);
  --hbr-color-brand-text-medium: var(--hbr-color-brand-50);
  --hbr-color-brand-text-weak: var(--hbr-color-brand-85);
  --hbr-color-brand-text-hover: var(--hbr-color-brand-40);
  --hbr-color-brand-text-medium-hover: var(--hbr-color-brand-45);
  --hbr-color-brand-text-weak-hover: var(--hbr-color-brand-85);
  --hbr-color-brand-text-disabled: var(--hbr-color-brand-80);
  --hbr-color-brand-vivid-text: var(--hbr-color-brand-95);
  --hbr-color-brand-contrast-text: var(--hbr-color-brand-90);
  --hbr-color-brand-icon: var(--hbr-color-brand-45);
  --hbr-color-brand-icon-hover: var(--hbr-color-brand-50);
  --hbr-color-brand-icon-selected: var(--hbr-color-brand-50);
  --hbr-color-brand-icon-disabled: var(--hbr-color-brand-80);
  --hbr-color-brand-icon-strong: var(--hbr-color-brand-45);
  --hbr-color-brand-icon-strong-hover: var(--hbr-color-brand-35);
  --hbr-color-brand-icon-strong-disabled: var(--hbr-color-brand-80);
  --hbr-color-brand-icon-medium: var(--hbr-color-brand-60);
  --hbr-color-brand-icon-weak: var(--hbr-color-brand-70);
  --hbr-color-brand-contrast-icon: var(--hbr-color-brand-95);
  --hbr-color-brand-contrast-icon-disabled: var(--hbr-color-brand-80);
  --hbr-color-brand-vivid-icon: var(--hbr-color-brand-98);
  --hbr-color-brand-border: var(--hbr-color-brand-50);
  --hbr-color-brand-border-medium: var(--hbr-color-brand-55);
  --hbr-color-brand-border-weak: var(--hbr-color-brand-60);
  --hbr-color-brand-border-hover: var(--hbr-color-brand-40);
  --hbr-color-brand-border-disabled: var(--hbr-color-brand-80);
  --hbr-color-brand-vivid-border: var(--hbr-color-brand-50);
  --hbr-color-brand-vivid-border-hover: var(--hbr-color-brand-85);
}

body.hbr-theme-magnetic.hbr-css__text-secondary-sm.hbr-mode-dark  {
   --hbr-color-brand-95: var(--hbr-color-blue-95);
   --hbr-color-brand-90: var(--hbr-color-blue-90);
   --hbr-color-brand-85: var(--hbr-color-blue-85);
   --hbr-color-brand-80: var(--hbr-color-blue-80);
   --hbr-color-brand-70: var(--hbr-color-blue-70);
   --hbr-color-brand-65: var(--hbr-color-blue-65);
   --hbr-color-brand-60: var(--hbr-color-blue-60);
   --hbr-color-brand-55: var(--hbr-color-blue-55);
   --hbr-color-brand-50: var(--hbr-color-blue-50);
   --hbr-color-brand-45: var(--hbr-color-blue-45);
   --hbr-color-brand-40: var(--hbr-color-blue-40);
   --hbr-color-brand-35: var(--hbr-color-blue-35);
   --hbr-color-brand-hover: var(--hbr-color-brand-70);
   --hbr-color-brand-active: var(--hbr-color-brand-70);
   --hbr-color-brand-selected: var(--hbr-color-brand-70);
   --hbr-color-brand-focus: var(--hbr-color-brand-70);
   --hbr-color-brand-disabled: #649EF580;
   --hbr-color-brand-highlight: #5191F040;
   --hbr-color-brand-medium: var(--hbr-color-brand-65);
   --hbr-color-brand-medium-disabled: #5191F080;
   --hbr-color-brand-weak: var(--hbr-color-brand-50);
   --hbr-color-brand-weak-highlight: #649EF540;
   --hbr-color-brand-bg: #649EF540;
   --hbr-color-brand-bg-medium: #2774D980;
   --hbr-color-brand-bg-weak: var(--hbr-color-brand-65);
   --hbr-color-brand-bg-hover: #649EF540;
   --hbr-color-brand-bg-medium-hover: #649EF520;
   --hbr-color-brand-bg-weak-hover: var(--hbr-color-brand-65);
   --hbr-color-brand-bg-selected: #649EF510;
   --hbr-color-brand-bg-weak-selected: var(--hbr-color-brand-70);
   --hbr-color-brand-bg-weak-selected-hover: var(--hbr-color-brand-80);
   --hbr-color-brand-bg-disabled: #5191F080;
   --hbr-color-brand-vivid-bg: var(--hbr-color-brand-50);
   --hbr-color-brand-text: var(--hbr-color-brand-70);
   --hbr-color-brand-text-medium: var(--hbr-color-brand-65);
   --hbr-color-brand-text-weak: var(--hbr-color-brand-60);
   --hbr-color-brand-text-hover: var(--hbr-color-brand-65);
   --hbr-color-brand-text-medium-hover: var(--hbr-color-brand-65);
   --hbr-color-brand-text-weak-hover: var(--hbr-color-brand-55);
   --hbr-color-brand-text-disabled: #649EF580;
   --hbr-color-brand-vivid-text: var(--hbr-color-brand-95);
   --hbr-color-brand-contrast-text: #2774D980;
   --hbr-color-brand-icon: var(--hbr-color-brand-80);
   --hbr-color-brand-icon-hover: var(--hbr-color-brand-95);
   --hbr-color-brand-icon-weak-hover: var(--hbr-color-brand-90);
   --hbr-color-brand-icon-weak-selected: var(--hbr-color-brand-90);
   --hbr-color-brand-contrast-icon: var(--hbr-color-brand-40);
   --hbr-color-brand-icon-disabled: #5191F080;
   --hbr-color-brand-contrast-icon-disabled: var(--hbr-color-brand-70);
   --hbr-color-brand-vivid-icon: var(--hbr-color-brand-98);
   --hbr-color-brand-border: var(--hbr-color-brand-70);
   --hbr-color-brand-border-medium: var(--hbr-color-brand-70);
   --hbr-color-brand-border-weak: var(--hbr-color-brand-80);
   --hbr-color-brand-border-hover: var(--hbr-color-brand-65);
   --hbr-color-brand-border-disabled: #5191F080;
   --hbr-color-brand-vivid-border: var(--hbr-color-brand-55);
   --hbr-color-brand-vivid-border-hover: var(--hbr-color-brand-85);
}

body.hbr-theme-magnetic.hbr-css__text-secondary-sm h1 {
   margin: 12px 0;
   letter-spacing: 0px;
   font-size: var(--hbr-font-size-xl);
   line-height: var(--hbr-font-line-height-dense);
   color: var(--hbr-color-neutral-text-medium, #373C42);
   font-family: var(--hbr-font-heading);
   font-weight: var(--hbr-font-weight-bold);
}

body.hbr-theme-magnetic.hbr-css__text-secondary-sm h2 {
   margin: 12px 0;
   letter-spacing: 0px;
   font-size: var(--hbr-font-size-lg);
   line-height: 24px;
   color: var(--hbr-color-neutral-text-medium, #373C42);
   font-family: var(--hbr-font-heading);
   font-weight: var(--hbr-font-weight-bold);
}

body.hbr-theme-magnetic.hbr-css__text-secondary-sm h3 {
   margin-top: 20px;
   margin-bottom: 10px;
   letter-spacing: 0px;
   font-size: var(--hbr-font-size-md);
   line-height: 22px;
   color: var(--hbr-color-neutral-text-medium, #373C42);
   font-family: var(--hbr-font-heading);
   font-weight: var(--hbr-font-weight-bold);
}

body.hbr-theme-magnetic.hbr-css__text-secondary-sm h4 {
   color: var(--header-2-color-primary, #535b62);
   font-weight: 400;
   font-size: 14px;
   line-height: 20px;
   margin-top: 36px;
   text-transform: uppercase;
   letter-spacing: 1px;
}


/**
  We shouldn't have this many "main containers" ,
  But in order to remove some work needs to be done in shell,
  which will probably come next --

  In the meantime this is a temporary hack so the entire frontend
  doesn't implode apart on the VM environment
*/
#__defaultPluginBody,
#main-content,
.dnacp-root-container,
.page-main {
   height: 100%;
   overflow: hidden;
}

.dnacp-root-container {
   display: flex;
   flex-direction: column;
}
